<template>
  <div class="sign-in-bg">
    <div class="sign-in-con">
      <h2 class="page-title">签到赚e币</h2>
      <div class="sign-wrap">
        <div class="sign-top-wrap">
          <div class="wrap-l">
            <div class="tip">签到累计赚取e币</div>
            <div class="e-total">{{signData.getTotal}}</div>
          </div>
          <div class="wrap-r">
            <div class="sign-btn">e币明细</div>
          </div>
        </div>
        <div class="sign-day-wrap">
          <div class="sign-tip">你已连续签到<span>{{signData.consecutiveDay}}</span>天</div>
          <div class="sign-items">
            <div :class="item.signNowStatus == '1' ? 'item was-sign' : 'item' " v-for="(item, index) in signData.consecutiveSign" :key="index">
              <div class="item-top">
                <div class="e-currency-logo"></div>
                <span>{{item.getCoins}}</span>
              </div>
              <div class="item-bottom" @click="signIn(index + 1)">{{item.days}}</div>
            </div>
          </div>
        </div>
      </div>
      <h2 class="page-title">分享赚e币</h2>
      <div class="sign-wrap">
        <table>
          <thead>
            <tr>
              <th>成功邀请用户</th>
              <th>手机号</th>
              <th>注册时间</th>
              <th>任务奖励</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in signData.inviteList" :key="index">
              <td>
                <div class="item">{{item.name}}</div>
              </td>
              <td>
                <div class="item">{{item.phone}}</div>
              </td>
              <td>
                <div class="item">{{item.createDate}}</div>
              </td>
              <td>
                <div class="item">{{item.price}}e币</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2 class="page-title">常见问题</h2>
      <div class="sign-wrap common-problem">
        <div class="problem-item">
          <div class="item-title-wrap">
            <i class="icon-a"></i>
            <span>关于签到赚e币</span>
          </div>
          <div class="item-faq">签到赚e币为答谢忠实用户，向忠实用户免费发放的福利。用户每日签到可获得60e币，连续签到7日，可获得200e币。</div>
        </div>
        <div class="problem-item">
          <div class="item-title-wrap">
            <i class="icon-b"></i>
            <span>关于邀请赚e币</span>
          </div>
          <div class="item-faq">邀请赚e币是为了答谢忠实用户的推广而赠送的奖励。成功邀请新用户注册即可获得200e币，不限次数。</div>
        </div>
        <div class="problem-item">
          <div class="item-title-wrap">
            <i class="icon-c"></i>
            <span>关于e币的用途</span>
          </div>
          <div class="item-faq">e币是用于下载3D模型和使用二维方案的虚拟货币，使用没有时间限制，用完为止。e币超出2000部分可兑换人民币提现，2000以内尽作为平台使用的虚拟货币。</div>
        </div>
        <div class="problem-item">
          <div class="item-title-wrap">
            <i class="icon-d"></i>
            <span>关于e币的获取</span>
          </div>
          <div class="get-e-coins-info-list">
            <table>
              <thead>
                <tr>
                  <th>获得途径</th>
                  <th>e币</th>
                  <th>每日上限</th>
                  <th>说明</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in getECoinsInfoList" :key="index">
                  <td>
                    <div class="item">{{item.access}}</div>
                  </td>
                  <td>
                    <div class="item">{{item.eCoins}}</div>
                  </td>
                  <td>
                    <div class="item">{{item.limit}}</div>
                  </td>
                  <td>
                    <div class="item">{{item.des}}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        token: null,
        getECoinsInfoList: [
          {
            access: "每日签到",
            eCoins: "10",
            limit: "10/天",
            des: "签到一次，赠送一次，连续签到7天赠送50"
          },
          {
            access: "邀请注册",
            eCoins: "30",
            limit: "20",
            des: "成功邀请新用户注册"
          },
          {
            access: "资料补充",
            eCoins: "100",
            limit: "100",
            des: "首次补充完善资料有效,后续修改资料无效"
          },
          {
            access: "绑定手机",
            eCoins: "50",
            limit: "50",
            des: "首次绑定有效，第二次修改绑定无效"
          },
          {
            access: "出售单页二维方案",
            eCoins: "20",
            limit: "无上限",
            des: "其他用户购买使用发布的单页二维方案"
          },
          {
            access: "出售项目二维方案",
            eCoins: "100",
            limit: "无上限",
            des: "其他用户购买使用发布的项目二维方案"
          },
          {
            access: "充值e币",
            eCoins: "1元=10e币",
            limit: "无上限",
            des: "充值购买可获得相应的e币"
          }
        ],
        signData: {}
      }
    },
    created() {
      this.init();
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.getSignStatus();
      },
      // 获取会员签到的数据和状态
      getSignStatus(){
        this.$api.signStatus({
          type: 0,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.signData = res.data;
          }
        }).catch(err => console.error(err));
      },
      // 签到
      signIn(i){
        let date = new Date().getDay();
        console.log(date)
        if(date != i) return false;
        this.$api.everydaySign({
          type: 0,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.getSignStatus();
          }
        }).catch(err => console.error(err));
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/signIn/signIn";
</style>
